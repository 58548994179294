import React from 'react'
import { ReduxBundlerProvider } from 'redux-bundler-hook'
import { Provider as ReaProvider } from 'reakit'
import { ThemeProvider } from 'styled-components'
import { IconContext } from 'react-icons'
import { MDXProvider as MdxProvider } from '@mdx-js/react'
import { Box, BoxProps, Text, TextProps } from 'rebass/styled-components'

import createStore from './bundles'
import theme from './theme'

const store = createStore()

interface Props {
  children: React.ReactNode
}

const Provider: React.FC = (props: Props): React.ReactElement => {
  const { children } = props

  return (
    <ReduxBundlerProvider store={store}>
      <ThemeProvider theme={theme}>
        <ReaProvider unstable_system={system}>
          <IconContext.Provider value={{ className: 'react-icon' }}>
            <MdxProvider components={mdxComponents}>{children}</MdxProvider>
          </IconContext.Provider>
        </ReaProvider>
      </ThemeProvider>
    </ReduxBundlerProvider>
  )
}

export default Provider

// NOTE: (mw) this wraps Reakit components as a Box component.
// now, i think i might prefer later we remove this and wrap explicitly.
const system = {
  // eslint-disable-next-line react/display-name
  useCreateElement: (type, props, children): React.ReactElement => {
    return (
      <Box {...props} as={type}>
        {children}
      </Box>
    )
  },
}

/* eslint-disable react/display-name */
const mdxComponents = {
  p: (props: TextProps): React.ReactElement => (
    <Text as="p" p={2} fontSize={1} fontFamily="body" {...props} />
  ),
  a: (props: TextProps): React.ReactElement => (
    <Text as="a" p={2} fontSize={1} fontFamily="body" {...props} />
  ),
  h1: (props: TextProps): React.ReactElement => (
    <Text as="h1" fontSize={6} fontFamily="body" {...props} />
  ),
  h2: (props: TextProps): React.ReactElement => (
    <Text
      as="h2"
      py={2}
      fontSize={5}
      fontFamily="body"
      color="primary.main"
      {...props}
    />
  ),
  h3: (props: TextProps): React.ReactElement => (
    <Text as="h3" py="2" fontSize={[2, 3, 4]} fontFamily="body" {...props} />
  ),
  h4: (props: TextProps): React.ReactElement => (
    <Text as="h4" p={2} fontSize={3} fontFamily="body" {...props} />
  ),
  h5: (props: TextProps): React.ReactElement => (
    <Text as="h5" p={2} fontSize={2} fontFamily="body" {...props} />
  ),
  // img
  // pre
  // code
  ol: (props: BoxProps): React.ReactElement => (
    <Box
      as="ol"
      p={2}
      css={{
        listStyle: 'decimal',
      }}
      {...props}
    />
  ),
  ul: (props: BoxProps): React.ReactElement => (
    <Box
      as="ul"
      p={2}
      css={{
        listStyle: 'disc',
      }}
      {...props}
    />
  ),
  li: (props: TextProps): React.ReactElement => (
    <Text as="li" p={2} ml={4} fontSize={1} fontFamily="body" {...props} />
  ),
  // blockquote
  // hr
  // table
  // tr
  // th
  // td
  // em
  // strong
  // delete
  // wrapper
  // inlineCode
  // thematicBreak
  div: (props: BoxProps): React.ReactElement => <Box {...props} />,
}

/* eslint-enable react/display-name */
