// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-link-oidc-callback-tsx": () => import("../src/pages/app-link/oidc-callback.tsx" /* webpackChunkName: "component---src-pages-app-link-oidc-callback-tsx" */),
  "component---src-pages-charity-tsx": () => import("../src/pages/charity.tsx" /* webpackChunkName: "component---src-pages-charity-tsx" */),
  "component---src-pages-feedback-tsx": () => import("../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-merchant-signup-tsx": () => import("../src/pages/merchant-signup.tsx" /* webpackChunkName: "component---src-pages-merchant-signup-tsx" */),
  "component---src-pages-merchant-tsx": () => import("../src/pages/merchant.tsx" /* webpackChunkName: "component---src-pages-merchant-tsx" */),
  "component---src-pages-using-tsx": () => import("../src/pages/using.tsx" /* webpackChunkName: "component---src-pages-using-tsx" */),
  "component---src-pages-welcome-aboard-tsx": () => import("../src/pages/welcome-aboard.tsx" /* webpackChunkName: "component---src-pages-welcome-aboard-tsx" */),
  "component---src-pages-where-tsx": () => import("../src/pages/where.tsx" /* webpackChunkName: "component---src-pages-where-tsx" */),
  "component---src-pages-terms-mdx": () => import("../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */),
  "component---src-pages-merchant-terms-mdx": () => import("../src/pages/merchant-terms.mdx" /* webpackChunkName: "component---src-pages-merchant-terms-mdx" */)
}

